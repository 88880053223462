<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" title="Certificate" @hidden="closeForm()" @close="closeForm()">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Standard and Releases" active>
            <b-row>
              <b-col lg="4">
                <b-form-group label="Company">
                  <b-form-input :value="data.contractstandard.company"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="VAT">
                  <b-form-input :value="data.contractstandard.vat"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Standard">
                  <b-form-input :value="data.contractstandard.standard"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Contract">
                  <b-form-input :value="data.contractstandard.contract"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Code">
                  <b-form-input :value="data.contractstandard.certificatecode"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="2">
                <b-form-group label="Contract Issue">
                  <b-form-input type="date" :value="data.contractstandard.issuedate"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Contract expiry">
                  <b-form-input type="date" :value="data.contractstandard.expiracydate"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Certificate first issue">
                  <b-form-input type="date" :value="data.contractstandard.certificatefirstissue"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Certificate from another cb">
                  <b-form-input :value="data.contractstandard.firstcertificatewithanothercb == true ? 'Yes' : 'No'"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="12">
                <b-form-group label="Releases">
                  <b-table no-border-collapse :items="data.releases" :fields="fields.releases">
                    <template v-slot:cell(sites)="row">
                      <div v-for="(item, index) of row.value" :key="index">
                        {{ item.address }} - {{ item.city }} - {{ item.postalcode }} - {{ item.statename }}
                        {{ item.ismain == true ? "Main" : "" }}
                        {{ item.isregistered == true ? "Registered" : "" }}
                        {{ item.isoperative == true ? "Operative" : "" }}
                      </div>
                    </template>
                    <template v-slot:cell(languages)="row">
                      <div v-for="(item, index) of row.value" :key="index">
                        <strong>{{ item.lang }}:</strong> {{ item.scope }}
                      </div>
                    </template>
                  </b-table>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Decisions">
            <b-btn variant="primary" size="sm" @click="addHistory">New</b-btn>
            <b-table no-border-collapse class="mt-2" :items="data.histories" :fields="fields.histories">
              <template v-slot:cell(date)="row">
                <b-form-input type="date" size="sm" v-model="row.item.date" :disabled="row.item.iafdate != null"/>
              </template>
              <template v-slot:cell(users_id)="row">
                <b-form-select v-model="row.item.users_id" :options="data.users" size="sm" value-field="id" text-field="val" :disabled="row.item.iafdate != null"/>
              </template>
              <template v-slot:cell(contractsstandardsreleases_id)="row">
                <b-form-select v-model="row.item.contractsstandardsreleases_id" :options="data.releases.map(x => ({'id': x.id, 'val': x.revnumber}))" size="sm" value-field="id" text-field="val" :disabled="row.item.iafdate != null"/>
              </template>
              <template v-slot:cell(contractsstandardsstatus_id)="row">
                <b-form-select v-model="row.item.contractsstandardsstatus_id" :options="data.contractsstandardsstatus" size="sm" value-field="id" text-field="val" :disabled="row.item.iafdate != null"/>
              </template>
              <template v-slot:cell(accreditated)="row">
                <b-form-select v-model="row.item.accreditated" :options="[{id: false, val: 'No'}, {id: true, val: 'Yes'}]" size="sm" value-field="id" text-field="val" :disabled="row.item.iafdate != null"/>
              </template>
              <template v-slot:cell(note)="row">
                <b-form-textarea v-model="row.item.note" rows="4" size="sm" :disabled="row.item.iafdate != null"/>
              </template>
              <template v-slot:cell(id)="row">
                <b-btn variant="danger" size="sm" @click="data.histories.splice(row.index, 1)" :disabled="row.item.iafdate != null">Delete</b-btn>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Checks">
            <b-table no-border-collapse :items="data.checks" :fields="fields.checks" />
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="loadData(data.contractstandard.id)">Refresh</b-btn>
          <b-btn variant="success" class="mr-2" @click="saveData">Save</b-btn>
          <b-btn variant="warning" class="mr-2" @click="uploadData">Upload</b-btn>
          <b-btn variant="info" @click="checkData">Check</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "certificate",
  data() {
    return {
      show: true,
      data: null,
      fields: {
        releases: [{
          key: 'revnumber',
          label: "Rev",
          sortable: true
        },{
          key: 'issuedate',
          label: "Issue",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'validfromdate',
          label: 'Valid-from',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'expiracydate',
          label: 'Expiry',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'sites',
          label: "Sites"
        },{
          key: 'languages',
          label: "Scopes"
        },{
          key: 'iafs',
          label: "IAFs"
        },{
          key: 'exclusions',
          label: "Exclusions"
        }],
        histories: [{
          key: 'date',
          label: "Date"
        },{
          key: 'users_id',
          label: "Decided by"
        },{
          key: 'contractsstandardsreleases_id',
          label: "Release"
        },{
          key: 'accreditated',
          label: 'Accreditated',
        },{
          key: 'contractsstandardsstatus_id',
          label: "State"
        },{
          key: 'note',
          label: "Note"
        },{
          key: 'iafdate',
          label: 'IAF'
        },{
          key: "id",
          label: ""
        }],
        checks: [{
          key: 'date',
          label: "Date",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'success',
          label: 'Result',
          formatter: value => {
            return value === true ? "Success" : "Failed";
          }
        },{
          key: 'iafjson',
          label: "JSON"
        }]
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    loadData(id){
      this.openHttp("contracts/certificate/", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    addHistory(){
      var d = new Date();
      this.data.histories.push({
        id: 0,
        contractsstandardsreleases_id: this.data.releases[0].id,
        contractsstandardsstatus_id: 1,
        date: [ d.getFullYear(), d.getMonth() + 1, d.getDate() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-'),
        note: null,
        accreditated: false,
        users_id: this.data.users[0].id
      });
    },
    saveData(){
      this.saveHttp("contracts/certificate/save", {id: this.data.contractstandard.id, histories: this.data.histories }, "Decisions saved!" ).then((result) => {
        if (result !== null) this.loadData(result);
      })
    },
    uploadData(){
      this.saveHttp("contracts/certificate/upload", {id: this.data.contractstandard.id }, "Decisions uploaded!" ).then((result) => {
        if (result !== null) this.loadData(result);
      })
    },
    checkData(){
      this.saveHttp("contracts/certificate/check", {id: this.data.contractstandard.id }, "Decisions checked!" ).then((result) => {
        if (result !== null) this.loadData(result);
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>